@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding:wght@400;700&display=swap");
    @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
    @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
    @import url("https://storage.googleapis.com/ledsp-props-and-tools-sets/change-u-staging/fonts/helveticaneue.woff2");

    @font-face {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: url("https://storage.googleapis.com/ledsp-props-and-tools-sets/change-u-staging/fonts/helveticaneue.woff2")
        format("woff2");
    }

    @font-face {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: url("https://storage.googleapis.com/ledsp-props-and-tools-sets/change-u-staging/fonts/helveticaneue-bold.woff2")
        format("woff2");
    }

    @font-face {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: url("https://storage.googleapis.com/ledsp-props-and-tools-sets/change-u-staging/fonts/helveticaneue-light.woff2")
        format("woff2");
    }



@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
}

svg,
img {
  -webkit-user-drag: none;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}


* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
